<template>
    <div class="project">
        <h1>Cinder Audio</h1>
        <div class="topInfo">
            <p>
                2012 - 2014<br>
                Source Code: part of the <a href="https://github.com/cinder/cinder">Cinder C++ Framework</a>
            </p>
        </div>
        <div class="content side-by-side">
            <b-img class="media audio-layers" right src="@/assets/projects/cinder-audio/audio_layers.png" />
            <p>
                While working at The Barbarian Group, I took on the redesign of the Cinder audio API
                (<code>ci::audio</code>), with the goals of creating something both powerful and flexible enough to be
                directly combined with Cinder's graphics capabilities. We went with a modular design in the likes of
                Pure Data and Web Audio, maintaining the spirit of providing C++ tools to build the engine you want.
            </p>
            <p>
                Below is a discussion of some of the nice features in this audio library, while you can refer to the
                <a href="https://libcinder.org/docs/guides/audio/index.html">cinder audio guide</a> and the
                <a href="https://github.com/cinder/Cinder/tree/master/samples/_audio">shipped samples</a> for in depth usage details.
            </p>
        </div>
        <div class="content">
            <!-- <div class="divider py-1 bg-primary"></div> -->
            <h2>Library Features</h2>
            <h4>Native Device Management</h4>
            <p>
                We wanted to have tight control over the audio processing at an OS level, so there is a hardware
                abstraction on each platform. This allows us to minimize dependencies and make tweaks when we need them
                even at production time. We support a number of platforms (Windows, OS X, iOS, Linux, Android), so I'd
                say this was the largest aspect of the project concerning development time. However once built, it's a
                great thing to have, especially when real-time low-latency is your target.
            </p>
            <h4>Built-in Nodes</h4>
            <p>
                Like any nice modular audio API, there are some nice things you can use out of the box to build custom
                audio engines or effects. For sample playback, there is the <code>BufferPlayerNode</code> (in-memory)
                and <code>FilePlayerNode</code> (streaming)\ (see
                <a href="https://libcinder.org/docs/guides/audio/index.html#read_audio">notes here</a>). For waveform
                generation, there are both low level nodes (sinewave, triangle, phase, etc) as well as the band-limited
                <code>GenOscNode</code> that has presets for the common waveform types. Nodes for filtering and delay
                are also in there, as well as general math operations or just using a C++11 lambda to do some audio
                processing. <a href="https://libcinder.org/docs/guides/audio/index.html#other_nodes">Details</a>.
            </p>
            <p>
                Nodes in <code>ci::audio</code> can be multi-channel for ease of use (default is they match their
                inputs, but this can be overridden). You use ChannelRouterNode to both separate channels from
                multi-channel nodes and to remap mono nodes, for example when designing a spatialized multi-channel
                audio engine.
            </p>
            <h4>Digital Signal Processing</h4>
            <p>
                At the core, there's an audio library for doing typical audio math, like vector math operations,
                windowing, sample-rate conversion, and FFT (Fast Fourier Transform). All the other components build on
                this efficiency-orientated layer, although the <code>cinder::audio::dsp</code> namespace is also meant
                to be used in end projects when needed.
            </p>
            <h4>Sample Accurate Scheduling</h4>
            <p>
                One very nice feature in <code>ci::audio</code> is the ability to schedule events with sub-sample
                accuracy. This allows you to synchronize things running on other threads (commonly visuals, but can also
                be networking events, etc). You do this by specifying a time in the future, usually within the next
                processing block. Audio params are controlled in a similar fashion although with a bit more control,
                using the <code>Param</code> mechanism. Most of the built-in nodes expose their parameters using these
                where it makes sense. Similar to the Web Audio API, you can also use other <code>ci::audio::Node</code>s
                as inputs to <code>ci::audio::Param</code>.
            </p>
        </div>
        <div class="content">
            <h2>Cinder Blocks (Add-ons)</h2>
            <p>
                Because of the modular structure and native C++ API, it's easy to extend <code>ci::audio</code>'s
                built-in functionality by adding custom Nodes for synthesis, effects, custom processing, or adding other
                platform-specific backends. Here are some that are public on github.
            </p>
            <h4>Blocks extending <code>ci::audio::Node</code>s</h4>
            <ul>
                <li>
                    <a href="https://github.com/richardeakin/Cinder-Stk">Cinder-Stk</a> - adds support for the
                    <a href="https://ccrma.stanford.edu/software/stk/index.html">Synthesis Toolkit</a>, also wrapping
                    many useful tools as Nodes for things like reverb, chorus, synth instruments, etc. Personally, I've
                    used it in many projects for the
                    <a href="https://ccrma.stanford.edu/software/stk/classstk_1_1FreeVerb.html">FreeVerb</a>
                    implementation.
                </li>
                <li>
                    <a href="https://github.com/richardeakin/Cinder-HISSConvolver">Cinder-HISSConvolver</a> - adds a
                    Node for convolution using the HISSTools Impulse Response Toolbox. Commonly used for convolution
                    reverb, or just create interesting sound designs on real-time audio signals.
                </li>
                <li>
                    <a href="https://github.com/notlion/Cinder-PureDataNode">Cinder-PureDataNode</a> - wraps Pure Data
                    within a Node, allowing you to write embedded pd patches, while also allowing you to use cinder to
                    handle difficult cross-platform things like hardware i/o, file i/o, sample-rate conversion, and
                    other low-level DSP operations. Also mentioned at PdCon16 (<a
                        href="http://www.nyu-waverlylabs.org/pdcon16/wp-content/uploads/2017/02/Proceedings_of_the_5th_International_Pure_Data_Convention.pdf"
                        >proceedings</a
                    >, "libpd: Past, Present, and Future of Embedding Pure Data).
                </li>
            </ul>
            <h4>Blocks using <code>ci::audio::Node</code>s</h4>
            <ul>
                <li>
                    <a href="Cinder-SoundPlayer">Cinder-SoundPlayer</a> - from
                    <a href="https://redpaperheart.com/">eRed Paper Heart</a>, provides higher-level audio file playback
                    common in user interfaces. dev branch has some nice improvements (from me) for playback via a
                    'Buffer pool'. This is crucial in things like sound effects where you want them to be able to
                    overlap.
                </li>
                <li>
                    <a href="https://github.com/Potion/Cinder-poSoundManager">Cinder-poSoundManager</a> - from Potion
                    Design, similar audio file playback tool, with some more features like pan, loop, etc.
                </li>
            </ul>
            <h4>Blocks adding extra Hardware Backends</h4>
            <ul>
                <li>
                    <a href="https://github.com/cinder/Cinder-PortAudio">Cinder-PortAudio</a> - allows you to use
                    PortAudio as an additional audio hardware backend, selectable at runtime, notably used for adding
                    ASIO / Dante support. More info on
                    <a
                        href="https://discourse.libcinder.org/t/cinder-portaudio-portaudio-as-an-alternative-audio-backend/1346"
                        >this forum post</a
                    >.
                </li>
            </ul>
        </div>
        <div class="content">
            <h2>Applications</h2>
            <p>
                Here are some projects that I've worked on using<code>ci::audio</code> that reach a bit beyond the
                run-of-the-mill sample file playback scenario.
            </p>
            <h4>Face Controlled Synth</h4>
            <p>
                A collaboration with <a href="https://rarevolume.com/">Rare Volume</a> using face movement and gestures
                to drive a combination of subtractive synthesis and studio audio compositions. Face joint positions and
                velocities were mapped to track volumes and parameters on a custom subtractive synthesis arrangement.
                The choice of audio tracks was driven based on extracted 'mood', which was mapped to the level of
                various tracks.
            </p>
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/351084708"
            ></b-embed>
            <h4>Falling Gears</h4>
            <p>
                This is a sample that
                <a href="https://github.com/cinder/Cinder/tree/master/samples/FallingGears/src">ships with cinder</a>,
                demonstrating physics driven audio synthesis. Gears fall when you drag your mouse and collisions between
                gears and walls or 'islands' trigger sound generators (<code>ci::audio::GenNode</code>s) that are
                spatially arranged to make musical chords.
            </p>
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/108706095"
            ></b-embed>
            <h4>Symphonologie</h4>
            <p>
                Another <a href="https://rarevolume.com/work/symphonologie/">Rare Volume project</a>, held at the Louvre
                in Paris. This time, visuals were driving audio. Five microphones were used to isolate different sections of the symphony, which were analyzed
                as amplitude envelopes and magnitude frequency spectrums. During the project, I added support for MSW
                low-latency mode, a key step in obtaining a tight and highly reactive music visualizer on the Windows platform.
            </p>
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/191749869"
            ></b-embed>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

@import "../../assets/projects/style.scss";
@import "../../assets/common.scss";

// .side-by-side {
//     border-color: red;
// }

.audio-layers {
    margin-left: 1em;
}

hr {
    color: yellow;
}
</style>